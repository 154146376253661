import { tw } from '@/utils/tw';
import CheckboxTheme from 'base/components/Checkbox/theme';

const { Caption } = CheckboxTheme;

const Checkbox = tw.theme({
  extend: CheckboxTheme,
  slots: {
    label: ['text-black', 'text-input-md'],
    input: [
      'active:bg-gray-100',
      'active:shadow-gray-900',
      'after:border-gray-900',
      'before:bg-secondary-200',
      'bg-white',
      'focus:shadow-secondary-700',
      'hover:shadow-black',
      'shadow-gray-900',
    ],
  },
  variants: {
    variant: {
      alternative: {
        label: ['text-black', 'text-input-md', 'ml-1'],
        input: [
          'active:bg-secondary-100',
          'checked:active:bg-secondary-700',
          'checked:after:bg-white',
          'checked:before:bg-white',
          'checked:bg-secondary-800',
          'checked:focus:bg-secondary-700',
          'checked:focus:shadow-secondary-400',
          'checked:hover:bg-secondary-700',
          'checked:shadow-inset-0',
          'focus:shadow-secondary-700',
          'hover:shadow-secondary-700',
          'outline-secondary-200',
          'shadow-md',
        ],
      },
    },
  },
});

export default Object.assign(Checkbox, { Caption });
