import { Icon } from '@/components/Icon';
import { LoopAround } from '@/components/LoopAround';
import { useAppState } from '@/hooks/useAppState';
import { LabradorComponent } from '@/types/component';
import { cn } from '@/utils/cn';
import { mergeProps } from '@/utils/merge';
import { LabradorJwVideoTeaser } from 'base/components/labrador/JwVideo/Teaser';

import { useRef } from 'react';

export const HantLabradorJwVideoTeaser: LabradorComponent = (props) => {
  const { badgeData, isFrontPage, prominentTag, displayProminentTagOnTeaser } = props.data;

  const { name: badgeName } = badgeData || {};

  const elemRef = useRef<HTMLDivElement>(null);

  const [{ affiliate }] = useAppState();

  const isNoje = affiliate === 'noje';

  const headline = badgeName ? (
    <LoopAround fragment={<span>{badgeName}</span>} />
  ) : displayProminentTagOnTeaser ? (
    <>
      {prominentTag}
      <Icon options={{ className: cn('rotate-180', '!h-4', '!w-4') }} name="sliderArrow" />
    </>
  ) : null;

  const badgeText = badgeName ?? (displayProminentTagOnTeaser && prominentTag);

  const isPriority = props.meta.teaserIndex < 4;

  props.data.hintText = '';

  return (
    <div ref={elemRef}>
      <LabradorJwVideoTeaser
        {...mergeProps(
          {
            data: {
              headline: isFrontPage ? headline : 'Videos från Hänt',
              ...(isFrontPage
                ? {
                    options: {
                      $link: {
                        'data-ticker-text': badgeText,
                      },
                      $headline: {
                        hideLine: true,
                      },
                      $media: {
                        $image: {
                          priority: isPriority,
                        },
                        $video: {
                          preload: isPriority ? 'auto' : 'metadata',
                        },
                      },
                    },
                  }
                : {
                    options: {
                      $content: {
                        colors: 'primary',
                        className: isNoje ? 'border-primary-700' : 'border-secondary-700',
                      },
                      $headline: {
                        hideLine: true,
                      },
                    },
                  }),
            },
          },
          props,
        )}
      />
    </div>
  );
};
